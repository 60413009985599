import React, { useState, useEffect } from 'react';
import './FixedNavbar.scss';
import Header from '../../Header/index';

const FixedNavbar = ({ categories, onCategoryClick, activeCategory }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 170) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const activeElement = document.querySelector(
      `.fixed-navbar-item.activeCat`
    );
    if (activeElement) {
      const scrollContainer = document.querySelector('.fixed-navbar-list');
      scrollContainer.scrollTo({
        left:
          activeElement.offsetLeft -
          scrollContainer.clientWidth / 2 +
          activeElement.clientWidth / 2,
        behavior: 'smooth',
      });
    }
  }, [activeCategory]);

  return (
    <nav className={`fixed-navbar ${isVisible ? 'visible' : ''}`}>
      <Header />
      <ul className="fixed-navbar-list">
        {categories.map((category) => (
          <li
            key={category.name}
            className={`fixed-navbar-item ${
              activeCategory === category.name ? 'activeCat' : ''
            }`}
            onClick={() => onCategoryClick(category.name)}
          >
            <button className="fixed-navbar-link">{category.name}</button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FixedNavbar;

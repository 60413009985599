import { useSelector } from 'react-redux';

export function useTelegram() {
  const tg = window.Telegram.WebApp;
  const MainButton = tg.MainButton;

  const orderId = String(Math.floor(Math.random() * 10000)).padStart(4, '0');
  const { totalPrice, cafeName, items } = useSelector((state) => state.cart);
  const { phone, address, comment, paymentMethod, serviceType } = useSelector(
    (state) => state.order
  );
  const { cafe } = useSelector((state) => state.cafe);

  const showMainButton = (pathname) => {
    if (totalPrice > 0) {
      if (pathname.includes('/cart')) {
        MainButton.setText('Заказать');
      } else {
        MainButton.setText(`К оформлению — ${totalPrice} ₽`);
      }
      MainButton.show();
    } else {
      MainButton.hide();
    }
  };
  const onClickMainButton = (navigate) => {
    if (MainButton.text.includes('К оформлению —')) {
      navigate('/cart');
      return;
    } else {
      if (serviceType === 'delivery' && (!address || address.trim() === '')) {
        tg.showAlert('Пожалуйста, введите адрес доставки.');
      } else if (!paymentMethod) {
        tg.showAlert('Пожалуйста, выберите способ оплаты.');
      } else if (serviceType === null) {
        tg.showAlert('Пожалуйста, выберите способ получения заказа.');
      } else {
        tg.showConfirm(
          'Вы уверены, что хотите оформить доставку?',
          (confirmed) => {
            if (confirmed) {
              const orderItems = items.map((item) => ({
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                sizeName: item.sizeName,
                category: item.category,
              }));
              const orderData = {
                orderId: orderId,
                totalPrice: totalPrice,
                cafeName: cafeName,
                cafeId: cafe.id,
                phone: phone,
                address: address,
                comment: comment,
                items: orderItems,
                paymentMethod: paymentMethod,
                serviceType: serviceType,
              };

              tg.sendData(JSON.stringify(orderData));
            }
          }
        );
      }
    }
  };

  return {
    tg,
    MainButton,
    showMainButton,
    onClickMainButton,
  };
}

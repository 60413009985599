import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Item.scss';
import { useTelegram } from '../../hooks/useTelegram';
import ItemModal from '../../components/ItemModal';
import ButtonInCart from './ButtonInCart/ButtonInCart';
import { addItem } from '../../redux/cartSlice';

const Item = ({ ...item }) => {
  const dispatch = useDispatch();
  const { MainButton, showMainButton } = useTelegram();

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [itemCount, setItemCount] = React.useState(0);
  const { items } = useSelector((s) => s.cart);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleAddItem = () => {
    dispatch(addItem({ item, quantity: 1 }));
  };

  React.useEffect(() => {
    if (isModalOpen) {
      MainButton.hide();
    } else {
      setTimeout(() => {
        showMainButton(window.location.pathname);
      }, 0);
    }
  }, [MainButton, isModalOpen, showMainButton]);

  const getItemCount = useCallback(() => {
    const cartItemsWithSameId = items.filter((i) => i.id === item.id);
    const totalQuantity = cartItemsWithSameId.reduce(
      (total, cartItem) => total + cartItem.quantity,
      0
    );
    return totalQuantity;
  }, [items, item.id]);

  useEffect(() => {
    setItemCount(getItemCount());
  }, [items, getItemCount]);

  return (
    <>
      <div className="menu-item">
        <img
          src={`${item.image_url.String}`}
          alt={item.name}
          className="menu-item__image"
          onClick={openModal}
          // loading="lazy"
        />
        <p className="menu-item__price" onClick={openModal}>
          {!item.sizes ? (
            item.price
          ) : (
            <>
              <span className="menu-item__price__from">от</span>{' '}
              {item.sizes[0].price}
            </>
          )}{' '}
          <span className="rubl">₽</span>
        </p>
        <h2 className="menu-item__name" onClick={openModal}>
          {item.name}
        </h2>

        {itemCount ? (
          <ButtonInCart
            openModal={openModal}
            itemCount={itemCount}
            item={item}
          />
        ) : (
          <>
            {!item.sizes ? (
              <button className="menu-item__button" onClick={handleAddItem}>
                <p className="menu-item__button__add">+</p>
              </button>
            ) : (
              <button className="menu-item__button" onClick={openModal}>
                <p>Выбрать</p>
              </button>
            )}
          </>
        )}
      </div>
      {isModalOpen && (
        <ItemModal open={isModalOpen} handleClose={closeModal} item={item} />
      )}
    </>
  );
};

export default Item;

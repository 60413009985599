import React, { useCallback, useRef } from 'react';
import { TbLocationFilled } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';

import './Address.scss';

import { setAddress } from '../../../redux/orderSlice';
import { useFocusHandlers } from '../../../hooks/useFocusHandlers';
import { useTelegram } from '../../../hooks/useTelegram';

const Address = ({ setIsFocused, commentInputRef }) => {
  const dispatch = useDispatch();
  const { MainButton } = useTelegram();
  const { address } = useSelector((s) => s.order);
  const serviceType = useSelector((state) => state.order.serviceType);
  const inputRef = useRef(null);

  const { handleBlur } = useFocusHandlers(setIsFocused);

  const handleAddressChange = useCallback(
    (e) => {
      if (e.target.value.length <= 40) {
        dispatch(setAddress(e.target.value));
      }
    },
    [dispatch]
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      commentInputRef.current.focus();
    }
  };

  const handleInputFocus = () => {
    inputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  return (
    <>
      {serviceType === 'delivery' ? (
        <div className="cart-detail">
          <div className="label">Адрес доставки</div>
          <div className="cart-detail-input-data">
            <div className="cart-detail-input-data__icon">
              <TbLocationFilled style={{ color: '#45a049' }} />
            </div>
            <input
              type="text"
              onChange={handleAddressChange}
              value={address}
              placeholder="Введите адрес"
              onFocus={() => {
                MainButton.hide();
                setIsFocused(true);
                handleInputFocus();
              }}
              onBlur={handleBlur}
              enterKeyHint="done"
              onKeyDown={handleKeyDown}
              ref={inputRef}
            />
          </div>
          {address.length > 3 && (
            <div className="delivery-notification">
              <>Доставка будет рассчитана позже</>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default Address;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './PickupOrDelivery.scss';
import { setServiceType } from '../../../redux/orderSlice';

import { TbTruckDelivery } from 'react-icons/tb';
import { TfiHandStop } from 'react-icons/tfi';

const PickupOrDelivery = () => {
  const dispatch = useDispatch();
  const serviceType = useSelector((state) => state.order.serviceType);

  const handleSelect = (serviceType) => {
    dispatch(setServiceType(serviceType));
  };

  return (
    <div className="cart-detail">
      <div className="payment-method">
        <button
          className={`payment-button ${
            serviceType === 'delivery' ? 'active' : ''
          }`}
          onClick={() => handleSelect('delivery')}
        >
          <div className="payment-button-icon">
            <TbTruckDelivery />
          </div>
          Доставка
        </button>
        <button
          className={`payment-button ${
            serviceType === 'pickup' ? 'active' : ''
          }`}
          onClick={() => handleSelect('pickup')}
        >
          <div className="payment-button-icon">
            <TfiHandStop />
          </div>
          Самовывоз
        </button>
      </div>
    </div>
  );
};

export default PickupOrDelivery;

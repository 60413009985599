import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPaymentMethod } from '../../../redux/orderSlice';

import './PaymentMethod.scss';
import { BsCashStack } from 'react-icons/bs';
import { IoCardOutline } from 'react-icons/io5';

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const selectedPayment = useSelector((state) => state.order.paymentMethod);

  const handleSelect = (method) => {
    dispatch(setPaymentMethod(method));
  };

  return (
    <div className={'cart-detail'}>
      <div className="label">Способ оплаты</div>
      <div className="payment-method">
        <button
          className={`payment-button ${
            selectedPayment === 'cash' ? 'active' : ''
          }`}
          onClick={() => handleSelect('cash')}
        >
          <div className="payment-button-icon">
            <BsCashStack />
          </div>
          Наличными
        </button>
        <button
          className={`payment-button ${
            selectedPayment === 'transfer' ? 'active' : ''
          }`}
          onClick={() => handleSelect('transfer')}
        >
          <div className="payment-button-icon">
            <IoCardOutline />
          </div>
          Переводом
        </button>
      </div>
    </div>
  );
};

export default PaymentMethod;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pribors: '',
  phone: '',
  address: '',
  comment: '',
  paymentMethod: null,
  serviceType: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setComment: (state, action) => {
      state.comment = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setServiceType: (state, action) => {
      state.serviceType = action.payload;
      if (action.payload === 'pickup') {
        state.address = '';
      }
    },
  },
});

export const {
  setPhone,
  setAddress,
  setComment,
  setPaymentMethod,
  setServiceType,
} = orderSlice.actions;

export default orderSlice.reducer;
